exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-get-in-contact-js": () => import("./../../../src/pages/get-in-contact.js" /* webpackChunkName: "component---src-pages-get-in-contact-js" */),
  "component---src-pages-healthcare-suppliers-js": () => import("./../../../src/pages/healthcare-suppliers.js" /* webpackChunkName: "component---src-pages-healthcare-suppliers-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-and-msp-js": () => import("./../../../src/pages/it-and-msp.js" /* webpackChunkName: "component---src-pages-it-and-msp-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-nurses-and-clinicians-js": () => import("./../../../src/pages/nurses-and-clinicians.js" /* webpackChunkName: "component---src-pages-nurses-and-clinicians-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-teammed-js": () => import("./../../../src/pages/teammed.js" /* webpackChunkName: "component---src-pages-teammed-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */)
}

